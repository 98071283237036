<template>
  <v-app>
  
       <!-- Diálogo de progresso -->
       <v-dialog v-model="showProgress" persistent max-width="300">
        <v-card>
          <v-card-title>
            Baixando arquivo...
          </v-card-title>
          <v-card-text>
            <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showFilter" persistent max-width="300">
        <v-card>
          <v-card-title>
           Mensagem
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            A Diferença entre as datas não pode ser superior a {{ dias }} dias.
          </v-card-text>
          <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
            <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-container style="background-color:#f8f9fb;" >
        <v-col cols="12" >
          <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :title_opcao="'Opção'" :produtos="tipos_funerarios"
          @filtrar="filterData" 
            ></filtro-component>
        </v-col>
      </v-container>
  
      <v-container style="background-color:#eee"  
      id="contrato"
      fluid
      tag="section">

      <v-row>
        <v-col
          v-for="(item, index) in organizados"
          :key="index"
          cols="12"
          sm="3"
          lg="3"
          v-if="selectedOpcao === 'Emissão de Nota'">

          <base-material-stats-card-nf
            elevation="1"
            :color="'rgb(71 130 180)'"
            :icon="'mdi-office-building'"
            :subIcon="'mdi-office-building'"
            :title="limitarTexto(item.empresaAssociada, 17)"
            :valuex="false"
            :subTextAdd="'- Paga ao '+  formatarNomeEmpresa(item.parametro)+ ' '"
            :subTextAddV="formatarMoeda(item.totalReceber)"
            :subTextAdd1="'- Recebe do '+  formatarNomeEmpresa(item.parametro)+ ' '"
            :subTextAdd1V="formatarMoeda(item.totalPagar)"
            :subTextAdd2="'- Desconto (80%): '"
            :subTextAdd2V="formatarMoeda(calcularDesconto(item))"
            :subTextAdd3="'- ' +formatarNomeEmpresa(item.empresaAssociada)+ ' Emite NF:  '"
            :subTextAdd3V="formatarMoeda(calcularPagarFinal(item))"
            :subTextAdd4="'- '+formatarNomeEmpresa(item.parametro)+ ' Emite NF:  '"
            :subTextAdd4V="formatarMoeda(calcularReceberFinal(item))"
          />
     </v-col>

     <v-col v-if="this.selectedOpcao == 'Serviço Adquirido'"
       cols="12"
       sm="6"
       lg="6">
     
      <base-material-stats-card 
        color="rgb(71 130 180)"
        icon="mdi-cash"
        title="Pago"
        sub-icon-color="red"
        :value='formatarMoeda(this.totalrecebido)'
        :valuex='false'
        :sub-text="''"
        />
    </v-col>

     <v-col  v-if="this.selectedOpcao == 'Serviço Prestado'"
       cols="12"
       sm="6"
       lg="6">
     
      <base-material-stats-card 
        color="rgb(71 130 180)"
        icon="mdi-cash"
        title="Recebido"
        sub-icon-color="red"
        :value='formatarMoeda(this.totalrecebido)'
        :valuex='false'
        :sub-text="''"
        />
    </v-col>
  </v-row>
  <br/>
  <v-divider></v-divider>
  <br/><br/>
     
  <base-material-card
    icon="mdi-sale"
    title="Consulta de Vendas entre Empresas"
    color="rgb(71 130 180)" style="margin-top:0px !important"
    class="px-5 py-0"
    :items-per-page="itemsPerPage"
    hide-default-footer
    :items-per-page-text="'Registros por página'"> 

      <v-row>
        <v-col cols="12">
  
        <v-data-table
          :headers="this.headers_vendas"
          :items="lista_dessert.flat()"
          :items-per-page-text="'Registros por página'" 
        >
        <template v-slot:top>
          <v-progress-linear
            v-if="isLoading" 
            indeterminate
            color="rgb(71 130 180)"
          ></v-progress-linear>
        </template>
        <template v-slot:item="row">
          <tr :class="{'cor-row': row.index % 2 !== 0}">
            <td class="linha" style="width: 24%; font-size: 11px !important;font-weight: bold;font-family: Verdana, Geneva, Tahoma, sans-serif;">
              {{ row.item.pagador ? row.item.pagador : 'Não Informado' }}
            </td>
            <td class="linha" style="width: 24%; ">{{ row.item.recebe }}</td>
            <td class="linha" style="width: 24%; ">{{ row.item.falecido }}</td>
            <td class="linha" style="width: 12%; ">{{ formatDateA(row.item.dt_vencimento) }}</td>
            <td class="linha" style="width: 10%; ">
              {{
                parseFloat(row.item.valor_venda).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
              }}
            </td>
            <td class="linha" style="width: 12%; ">
              {{
                parseFloat(row.item.valor_pago).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
              }}
            </td>
            <td class="linha" style="width: 10%; ">{{ formatDateA(row.item.dt_pagamento) }}</td>
            <td class="linha" style="width: 8%; ">{{ row.item.id_atendimento }}</td>
          </tr>
        </template> 


      </v-data-table>
    </v-col>  
  </v-row>
     


      <span  v-if="this.selectedOpcao == 'Serviço Adquirido'" >Total a Pagar: </span>
      <v-list dense v-if="this.selectedOpcao == 'Serviço Adquirido'">
                <v-list-item v-for="(item, index) in lista_resumo" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.recebedor }}: 

                      {{
                      parseFloat(item.valorPagar).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}

                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              
      <br/>

      <div class="d-flex justify-end" style="color:rgb(71 130 180); font-size: 48px; margin-right: 12px;">
           

            <span @click="download_pdf"  style="color:red; font-size: 48px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-pdf"></i> 
            </span>

            <span @click="download_xls"  style="color:green; font-size: 48px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-excel"></i> 
            </span>
      </div>
    
      </base-material-card>
  
  
    </v-container>
  
  </v-app> 
  </template>
  
  <script>
  import Contrato from '../../services/contrato'
  import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import autoTable from 'jspdf-autotable';
  import logo from '@/assets/logo_grupo.png'; // Importe a imagem como um módulo

    export default {
    components: {
      FiltroComponent
    },

   

    computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = this.currentPage * this.itemsPerPage;
      return this.lista_dessert.flat().slice(startIndex, endIndex);
    },
  },
    
    filters: {
      formatDate(date) {
        if (!date) return '';
        const parsedDate = new Date(date);
        const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        const year = parsedDate.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },
  
  
  
  
      name: 'Contrato',
   
      data () {
        return {
  
          rules: {
            required: value => !!value || 'Obrigatório.',
          },
          
          currentPage: 1, // Página atual
          itemsPerPage: 20, // Itens por página
          pageNumber: 1, // Número da página
          totalPages:0,
          selectedStartDate: "",
          selectedEndDate: "",
          totalRecords:0,
          totalrecebido:0,
          totalEsperado:0,
          totalpagar:0,
          totalpago:0,
          total_contrato:0,
          showProgress: false,
          showFilter: false,
          dias: 0,
          headers_vendas: [
            {
              text: 'Pagador',
              align: 'start',
              sortable: false,
              value: 'pagador',
            },
            { text: 'Serviço Prestado', value: 'recebe',   sortable: false, },
            { text: 'Falecido', value: 'falecido',   sortable: false, },
            { text: 'Vencimento', value: 'dt_vencimento' ,   sortable: false,},
            { text: 'Valor Venda', value: 'valor_venda',   sortable: false, },
            { text: 'Valor Pago', value: 'valor_pago',   sortable: false, },
            { text: 'Pagamento', value: 'dt_pagamento',   sortable: false, },
            { text: 'Atendimento', value: 'id_atendimento',   sortable: false, },

          ],
  
          produto: 'Todos',
          lista_dessert: [],
          lista_resumo: [],

          empresa: 'Todas',
          selectedYear: "Todos",
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          selectMode: "Venda",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: ["Todas", "Serviço Prestado", "Serviço Adquirido", "Emissão de Nota"],
          modos: ["Conclusão do Atendimento", "Pagamento"],
          tipos: ["Todos"],
          organizados: [], // Inicializa a lista vazia
          calcularDesconto(item) {
            return Math.min(item.totalReceber, item.totalPagar) * 0.8;
          },
          calcularPagarFinal(item) {
            return item.totalPagar - this.calcularDesconto(item);
          },
          calcularReceberFinal(item) {
            return item.totalReceber - this.calcularDesconto(item);
          },

          tipos_funerarios: [
                "Todos",
              ],
  

        }
    },
  
    computed: {
      startIndex() {
        return (this.currentPage - 1) * this.itemsPerPage;
      },   
     
    },
  
    created() {
      // Defina a data atual como valor inicial para selectedStartDate
      this.loadPageData();
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      this.selectedStartDate = `${"01"}/${month}/${year}`;
      this.selectedEndDate = `${day}/${month}/${year}`;
    },
  
  
      methods: {
  
        changePage() {
          this.isLoading = true;
          this.loadPageData();
          this.fetchData(this.currentPage);
  
       },

        async loadImageAsBase64(url) {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Erro ao carregar a imagem: ${response.statusText}`);
          }
          const blob = await response.blob();
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(new Error("Erro ao ler a imagem"));
            reader.readAsDataURL(blob);
          });
        },
  
      formatDateA(date) {
          if (date === null || date === undefined) return ''; // Verifica se é nulo ou indefinido
          const [year, month, day] = date.split('-');
          return `${day}/${month}/${year}`;
      },

       loadPageData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      },
       
        filterData(valores) {

          this.isLoading = true;
          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.produto = valores.selectedProduto;


          this.currentPage = 1, // Página atual
          this.totalRecords = 0;
          this.fetchData(1);
  
        },

        async download_pdf() {
          const usuario = JSON.parse(sessionStorage.getItem('user')).nome;
          
          const doc = new jsPDF({ orientation: "landscape" });
          // Converte a imagem para Base64
          const imgBase64 = await this.loadImageAsBase64(logo);
          const imgWidth = 50; // Largura da imagem
          const imgHeight = 35; // Altura da imagem
          const x = 10; // Posição X da imagem
          const y = 10;

          if (!imgBase64.startsWith("data:image")) {
            throw new Error("A imagem não está no formato esperado");
          }

          // Adiciona a imagem
          doc.addImage(imgBase64, "PNG", x, y, imgWidth, imgHeight);

          // Adicionar título (ao lado da imagem)
          doc.setFontSize(18);
          doc.text("Relatório de Serviços Entre Empresas", x + imgWidth + 10, y + 10); // 10 de distância da imagem

             // Adicionar subtítulo abaixo
          doc.setFontSize(14);
          doc.text("Empresa(s): " +this.selectedCompany, x + imgWidth + 10, y + 23); // 25 de distância para o subtítulo abaixo


          // Adicionar subtítulo abaixo
          doc.setFontSize(14);
          doc.text("Listagem", x + imgWidth + 10, y + 29); // 28 de distância para o subtítulo abaixo

        

          // Adicionar tabela principal
        autoTable(doc, {
          head: [["Pagador", "Recebe", "Falecido - Atendimento", "Vencimento", "Valor Venda", "Valor Pago", "Pagamento"]],
          body: this.lista_dessert.flat().map(item => [
            item.pagador || "Não Informado",
            item.recebe,
            item.falecido + " - (" + item.id_atendimento + ")",
            formatDateB(item.dt_vencimento),
            parseFloat(item.valor_venda).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
            parseFloat(item.valor_pago).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
            formatDateB(item.dt_pagamento)
          ]),
          startY: y + imgHeight + 10, // Posição inicial ajustada
          columnStyles: {
            2: { cellWidth: 50 }, // Define a largura da coluna "Falecido - Atendimento" (índice 2)
          },

         });
           // Adicionar o total de registros no final da tabela
          const totalRegistros = this.lista_dessert.flat().length;
          doc.setFontSize(12);
          doc.text(`Total de atendimentos: ${totalRegistros}`, 10, doc.lastAutoTable.finalY + 10); // `doc.lastAutoTable.finalY` pega a posição final da tabela



          // Forçar os cards para a próxima página
          doc.addPage();

             // Adiciona a imagem
             doc.addImage(imgBase64, "PNG", x, y, imgWidth, imgHeight);

            // Adicionar título (ao lado da imagem)
            doc.setFontSize(18);
            doc.text("Relatório de Serviços Entre Empresas", x + imgWidth + 10, y + 10); // 10 de distância da imagem

              // Adicionar subtítulo abaixo
            doc.setFontSize(14);
            doc.text("Empresa(s): " +this.selectedCompany , x + imgWidth + 10, y + 23); // 25 de distância para o subtítulo abaixo

            // Adicionar subtítulo abaixo
            doc.setFontSize(14);
            doc.text("Resumo", x + imgWidth + 10, y + 29); // 28 de distância para o subtítulo abaixo

                      // Configurações iniciais
            let yPosition = y + 60; // Posição inicial para os cards
            let xPosition = 10; // Posição inicial para a primeira coluna
            const pageHeight = doc.internal.pageSize.height; // Altura da página
            const columnWidth = 100; // Largura de cada coluna
            const lineHeight = 6; // Altura de cada linha de texto
            const gapBetweenColumns = 10; // Espaço entre as colunas

            this.organizados.forEach((item, index) => {
              // Verifica se há espaço suficiente na coluna atual
              if (yPosition + 5 * lineHeight > pageHeight - 10) {
                // Se não há espaço, move para a próxima coluna
                xPosition += columnWidth + gapBetweenColumns;
                yPosition = y + 60; // Reseta a posição vertical para o início

                // Verifica se ambas as colunas estão cheias
                if (xPosition + columnWidth > doc.internal.pageSize.width) {
                  doc.addPage(); // Adiciona uma nova página
                  xPosition = 10; // Reseta para a primeira coluna
                  yPosition = y + 60; // Reseta a posição vertical
                }
              }

              // Adiciona título do card
              doc.setFontSize(14);
              doc.text(item.empresaAssociada.toUpperCase(), xPosition, yPosition);

              // Adiciona informações do card
              doc.setFontSize(12);
              const cardContent = [
                `- Paga ao ${this.formatarNomeEmpresa(item.parametro)}: ${this.formatarMoeda(item.totalReceber)}`,
                `- Recebe do ${this.formatarNomeEmpresa(item.parametro)}: ${this.formatarMoeda(item.totalPagar)}`,
                `- Desconto (80%): ${this.formatarMoeda(this.calcularDesconto(item))}`,
                `- ${this.formatarNomeEmpresa(item.empresaAssociada)} Emite NF: ${this.formatarMoeda(this.calcularPagarFinal(item))}`,
                `- ${this.formatarNomeEmpresa(item.parametro)} Emite NF: ${this.formatarMoeda(this.calcularReceberFinal(item))}`,
              ];

              cardContent.forEach((line, index) => {
                doc.text(line, xPosition, yPosition + lineHeight * (index + 1)); // Adiciona cada linha do card
              });

              // Atualiza a posição vertical para o próximo card
              yPosition += cardContent.length * lineHeight + 10;
            });

            const currentDate = new Date();

              // Adicionar a data de geração no final
                // Formatar a data e hora
            const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
            const formattedTime = `${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;

            // Combinar data e hora
            const formattedDateTime = `${formattedDate} ${formattedTime}`;   doc.setFontSize(10);
            const footerText = `Documento gerado em ${formattedDateTime} por ${usuario}`;
            const textWidth = doc.getTextWidth(footerText);
            const xxPosition = (doc.internal.pageSize.width - textWidth) / 2; // Centralizar o texto
            doc.text(footerText, xxPosition, pageHeight - 10); // Posicionar no rodapé, 10 un

          // Salvar o PDF
          doc.save("vendas-entre-empresas.pdf");

        },

        formatarNomeEmpresa(empresa) {
          switch (empresa.toUpperCase()) {
            case "MEMORIAL PARQUE MACEIÓ":
            case "MEMORIAL PARQUE MACEIO":
              return "Memorial";
            case "PREVIDA":
              return "Previda";
            case "PARQUE DO AGRESTE":
              return "AM";
            case "PARQUE DAS FLORES":
              return "Parque";
            case "CEMITERIO PREVIDA":
              return "Versan";
            default:
              return empresa; // Retorna o nome original se não houver correspondência
          }
        },

        limitarTexto(texto, limite) {
          return texto.length > limite ? texto.substring(0, limite) + '...' : texto;
        },

        getModelo(valor) {
          if (valor.includes('- Online')) {
              return 'mdi-web';
          } else {
              return 'mdi-store';
          }
      } ,

  
        formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
         },
         

        download_xls() {

              this.showProgress = true;              
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);


              if (diferencaEmDias > 365) {
                this.isLoading = false;
                this.showFilter = true;
                this.dias = 365;
                return 
              }


              Contrato.lista_contrato_vendas_empresa_xls(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto)
              .then(response => {
                        if (response.status === 200) {
                         
                                const fileName = `vendas_empresas_${Date.now()}.xlsx`;
                                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                                const fileLink = document.createElement('a')
                                fileLink.href = fileURL
                                fileLink.setAttribute('download', fileName)
                                document.body.appendChild(fileLink)
                                fileLink.click()
                             
                        }
                      })
                      .catch(error => {
                        // Lida com erros
                        console.error('Erro ao baixar o arquivo Excel:', error);
                      }) .finally(() => {
                        // Após o término do download, oculte o diálogo de progresso
                        this.showProgress = false;
                      });
            },
  
         
          calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
          },

          fetchData(pageNumber) {

            const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

              if (diferencaEmDias > 365) {
                this.isLoading = false;
                this.showFilter = true;
                this.dias = 365;
                return 
              }


              Contrato.lista_vendas_entre_empresas(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto, pageNumber, this.itemsPerPage)
                .then(response => {
                  if (response.status === 200) {
                    this.lista_dessert = response.data.content;
                    this.totalPages = response.data.totalPages; // Total de páginas disponíveis
                    this.loading = false; // Esconder o loading após a pesquisa
                    this.totalEsperado = response.data.totalEsperado; // Substitua totalRecordsFromApi pelo valor real
                    this.totalrecebido = response.data.totalrecebido; // Substitua totalRecordsFromApi pelo valor real
                    this.lista_resumo = response.data.resumo;
                    this.organizados = response.data.organizados;

                  }
                })
                .catch(e => {
                  // Lida com erros
                  this.isLoading = false;
                  this.lista_dessert = [];
                  this.lista_resumo = [];

                  this.totalRecords = 0;
                  this.totalrecebido = 0;
                  this.totalEsperado = 0;
                  this.total_contrato = 0;
                })
                .finally(() => {
                  this.isLoading = false;
                });
            }
      }  
    }

    function formatDateB(date) {
      if (date === null || date === undefined) return ''; // Verifica se é nulo ou indefinido
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    }
  
    function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }
  
  
  </script>
  
  
  <style scoped>
    .v-progress-circular {
      margin: 1rem;
    }
  
    .custom-gray-background {
      height: 80px;
    }
    
    .row {
      display: flex;
      flex-wrap: wrap;
       flex: 0 1 auto !important; 
      margin: -12px;
  }
  
  
    /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
    .fill-width {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      display: flex;
    }

   
.linha{

  font-size: 11px !important;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.no-hover-effect.v-data-table tbody tr:hover {
  color: rgb(71 130 180);
}
    
    /* Centralize horizontalmente */
    .justify-center {
      justify-content: center;
    }
    
      /*rgb(71 130 180)*/
.gray-row {
  background-color: rgb(71 130 180); /* ou qualquer outra cor cinza que você preferir */
}
.cor-row {
  background-color: rgb(231 244 255)   ;
  transition: none !important; /* Desativa a transição de cor */

}
    
   
    
    </style>
  